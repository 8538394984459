.smalltalk {
  z-index: 100;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: opacity .2s;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.smalltalk + .smalltalk {
  transition: all 1s;
}

.smalltalk .page {
  color: #333;
  z-index: 0;
  background: #fff;
  border-radius: 3px;
  min-width: 400px;
  padding: 0;
  position: relative;
  box-shadow: 0 4px 23px 5px #0003, 0 2px 6px #00000026;
}

@media only screen and (width <= 500px) {
  .smalltalk .page {
    min-width: 0;
  }
}

.smalltalk .page > .close-button {
  z-index: 1;
  background-image: url("IDR_CLOSE_DIALOG.0c9dac32.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 7px;
  right: 7px;
}

.smalltalk .page > .close-button:hover {
  background-image: url("IDR_CLOSE_DIALOG_H.08755d64.png");
}

.smalltalk .progress {
  width: 100%;
  display: block;
}

.smalltalk .page header {
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  color: #333;
  text-shadow: 0 1px 2px #fff;
  max-width: 500px;
  margin: 0;
  padding: 14px 17px;
  font-size: 120%;
  font-weight: bold;
  overflow: hidden;
}

.smalltalk .page .content-area {
  text-overflow: ellipsis;
  padding: 6px 17px;
  position: relative;
  overflow: hidden;
}

.smalltalk .page .action-area {
  padding: 14px 17px;
}

button {
  font-family: Ubuntu, Arial, sans-serif;
}

.smalltalk button, .smalltalk .smalltalk {
  min-width: 4em;
  min-height: 2em;
}

.smalltalk button {
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  color: #444;
  font: inherit;
  text-shadow: 0 1px #f0f0f0;
  background-image: linear-gradient(#ededed, #ededed 38%, #dedede);
  border: 1px solid #00000040;
  border-radius: 2px;
  margin: 0 1px 0 0;
  box-shadow: 0 1px #00000014, inset 0 1px 2px #ffffffbf;
}

.smalltalk button::-moz-focus-inner {
  border: 0;
}

.smalltalk button:enabled:active {
  box-shadow: none;
  text-shadow: none;
  background-image: linear-gradient(#e7e7e7, #e7e7e7 38%, #d7d7d7);
}

.smalltalk .page .button-strip {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.smalltalk .page .button-strip > button {
  margin-left: 10px;
}

.smalltalk input {
  box-sizing: border-box;
  color: #444;
  width: 100%;
  font: inherit;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  outline: none;
  min-height: 2em;
  margin: 0;
  padding: 3px;
}

.smalltalk button:enabled:focus, .smalltalk input:enabled:focus {
  border-color: #4d90fe;
  outline: none;
  transition: border-color .2s;
}

:root {
  --accent: #61afef;
}

* {
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #282c34;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.smalltalk .page {
  max-width: 500px !important;
  line-height: 150% !important;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.padded-wrap {
  padding: 1em 2em;
}
/*# sourceMappingURL=index.7125fb5a.css.map */
